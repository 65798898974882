<template>
  <div class="washNav content-index" v-loading="loading">
    <el-page-header
        style="width: 100%;padding:20px"
        @back="$router.go(-1)"
        content="送洗"
      ></el-page-header>
    <div class="centent">
      <h2>请选择您要操作送洗哪一项</h2>
      <p><span class="icon">⁎</span><span>温馨提示:请在物流取走前统一操作送洗，切勿频繁多次操作送洗！</span></p>
      <div v-if="factoryList.length != 0">
        <p
          class="floater"
          v-for="(item, index) in factoryList"
          :key="index"
          :class="pName[index]"
          @click="goToWashTable(item)"
        >
          {{ item.washFactoryName }}
        </p>
      </div>
      <div v-else class="washToust">当前门店未配置工厂，请联系客服！</div>
    </div>
  </div>
</template>

<script>
import { getFactoryName } from "@/api/factory/factoryConfirm.js";
export default {
  name: "WashNav",
  data() {
    return {
      factoryList: [],
      loading: false,
      pName: ["left", "right", "factoryName"],
    };
  },
  created() {
    this.factoryName();
  },
  methods: {
    async factoryName() {
      this.loading = true;
      const { data: res } = await getFactoryName();
      console.log("aaasasasas", res);
      if (res.code != 0) return this.$message.error(res.msg);
      this.factoryList = res.data;
      this.loading = false;
    },
    goToWashTable(data) {
      this.$router.push({
        path: "/home/more/sendWashTable",
        query: {
          index: data.factoryType,
          factoryName: data.factoryName,
          factoryId: data.factoryId
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
h2,
p {
  margin: 0;
}
.washNav {
  position: relative;
   overflow: auto;
  .centent {
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
    h2 {
      font-size: 26px;
      text-align: center;
    }
    > p {
      margin-top: 20px;
      font-size: 18px;
      font-weight: 700;
      display: flex;
      .icon{
        font-size: 18px;
        color: #f56c6c ;
        position: relative;
        top: -1px;
      }
    }
    div {
      width: 100%;
      display: flex;
      margin-top: 20px;
      justify-content: center;
      p {
        width: 200px;
        height: 200px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin: 0 25px;
        color: #fff;
        font-size: 18px;
        font-weight: 700;
      }
      p:nth-child(1) {
        background-color: #0f6be3;
      }
      p:nth-child(2) {
        background-color: #c30e04;
      }
      p:nth-child(3) {
        background-color: #409eff;
      }
    }
  }
}
.washToust {
  padding: 25px;
  border: 3px dashed #000;
  color: #606266;
  box-shadow: 0px 60px 30px -30px rgba(0, 0, 0, 0.3);
}
.floater {
  box-shadow: 0px 60px 30px -30px rgba(0, 0, 0, 0.3);
  transition: all 600ms ease;
  animation-play-state: paused;
}
.floater.left {
  animation: breathe 3000ms linear infinite alternate;
}
.floater.left:hover {
  animation-play-state: paused;
}
.floater.right {
  animation: breathe 2000ms linear infinite alternate;
}
.floater.right:hover {
  animation-play-state: paused;
}
@keyframes breathe {
  0% {
    box-shadow: 0px 13px 30px -10px rgba(0, 0, 0, 0.8);
    transform: rotate3d(0, 0, 0, 5deg) translate3d(0, 0, 0);
    opacity: 0.8;
  }
  50% {
    box-shadow: 0px 60px 30px -30px rgba(0, 0, 0, 0.3);
    transform: rotate3d(-30, 0, -10, 2deg) translate3d(2px, -2px, 30px);
    opacity: 1;
  }
  100% {
    box-shadow: 0px 13px 30px -10px rgba(0, 0, 0, 0.8);
    transform: rotate3d(0, 0, 0, 5deg) translate3d(0, 0, 0);
    opacity: 0.8;
  }
}
</style>
